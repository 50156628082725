import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  AuthenticationMethodEnum,
  PlanUpgradeService,
  UserTypeEnum,
} from '@cai-services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../../../core/_models/user.model';
import { AppFileService } from '../../../core/_services/app-file.service';
import { SessionService } from '../../../core/_services/session.service';
import { environment } from '../../../environments';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';
import { WalletUtil } from '../../../utils/wallet.util';
import { MenuHorizontalService, Office } from '../../../core';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @HostBinding('class') classes = 'kt-header__topbar kt-grid__item';
  @Input() activeOffice: Office;
  @Input() companyOffices: Office[];
  currentUser: User;
  UserTypeEnum = UserTypeEnum;
  isEmbedMode: boolean;
  shouldDisplayUpgradeButton = false;
  features = environment.features;
  currentOffice = this.sessionService.getSelectedOffice();
  private officeChangedStatusSub: Subscription;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly sessionService: SessionService,
    private readonly fileService: AppFileService,
    private readonly ref: ChangeDetectorRef,
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly menuHorizontalService: MenuHorizontalService,
    private readonly planUpgradeService: PlanUpgradeService,
  ) {
    this.currentUser = this.sessionService.getCurrentUser();
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.isEmbedMode = WalletUtil.isEmbedMode(this.appProperties, params);
    });
    this.sessionService.watchSessionStorage().subscribe(async (res) => {
      if (res.key === 'currentUser') {
        this.currentUser = res.data;
        this.shouldDisplayUpgradeButton =
          (await this.menuHorizontalService.shouldShowUpgradeButton(
            this.currentUser,
          )) && !this.isCargoWallet;
      }
    });

    if (this.sessionService.isCargoMart && this.isForwarder) {
      this.officeChangedStatusSub =
        this.sessionService.officeChangedStatusObs.subscribe(async () => {
          this.shouldDisplayUpgradeButton =
            (await this.menuHorizontalService.shouldShowUpgradeButton(
              this.currentUser,
            )) && !this.isCargoWallet;
        });
    }
  }
  isTopBarEnabled() {
    if (!this.currentUser) {
      return false;
    }
    return (
      this.currentUser.authenticationMethod !== AuthenticationMethodEnum.TOKEN
    );
  }

  get isForwarder(): boolean {
    return this.currentUser?.type === UserTypeEnum.FORWARDER;
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  onUpgradePlanClick() {
    this.planUpgradeService.captureUpgradeEvent('SI', 0);
    const targetUrl = 'settings/office-settings#viewplans';
    if (this.router.url === `/${targetUrl}`) {
      window.location.reload();
    } else {
      this.router.navigateByUrl(targetUrl);
    }
  }
}
