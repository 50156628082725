import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AirportService,
  CityService,
  CompanyService,
  CompanySubTypeEnum,
  CompanyTypeEnum,
  ICity,
  IReferrer,
  MeasurementSystemEnum,
  ReferralService,
  UserStatusEnum,
  UserTypeEnum,
} from '@cai-services';
import intlTelInput from 'intl-tel-input';
import { Observable, lastValueFrom, of } from 'rxjs';
import { delay, map, startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AirportLight } from '../../../core/_models/airport-light.model';
import { Company } from '../../../core/_models/company.model';
import { SessionService } from '../../../core/_services/session.service';
import { RegisterService } from '../../../core/_services/register.service';
import { IntercomService } from '../../../core/_services/intercom.service';
import { Forwarder } from '../../../core/_models/forwarder.model';
import { BrandIconUtil } from '../../../utils/brand.util';
import { AutoCompleteValidators } from '../../../validators/must-in-list.validator';
import { LoginFormValidators } from '../../../validators/loginForm.validator';
import { AirlineUser } from '../../../core/_models/airline-user.model';
import { Country } from '../../../core/_models/country.model';
import { Contact } from '../../../core/_models/contact.model';
import { IntlTelFormatEnum } from '../../../core/_enums/intl-tel-format.enum';
import { User } from '../../../core/_models/user.model';
import { environment } from '../../../environments';
import { SubscriptionEnum } from '../../../core/_enums/subscription.enum';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';

const LOADING_BUFFER_TIME_IN_SEC = 3,
  COMPANIES_AUTOCOMPLETE_MIN_LENGTH = 3,
  AIRPORTS_AUTOCOMPLETE_MIN_LENGTH = 1,
  AIRPORT_CODE = 'Airport Code';

@Component({
  selector: 'kt-complete-registration',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          maxHeight: '500px',
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          maxHeight: '0',
          overflow: 'hidden',
          opacity: 0,
          marginTop: 0,
          marginBottom: 0,
        }),
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.5s 0.3s')]),
    ]),
  ],
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('airportAutocomplete') airportAutocomplete: MatAutocomplete;
  @ViewChild('fullCompanyName')
  fullCompanyNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('airlineName') airlineNameInput: ElementRef<HTMLInputElement>;
  @Output() onFocus = new EventEmitter();

  registerForm: FormGroup;
  user: any;
  token: any;
  userType: UserTypeEnum;
  airports: AirportLight[];
  gsa: Company[];
  countries: any[];
  allCompanies: Company[];
  companies: Company[];
  success: boolean;
  isActivated: boolean;
  isLoading = false;
  isFormOpened = false;
  phoneMask = '012-345-6789';
  public filteredAirports: Observable<AirportLight[]>;
  public filteredCountries: Observable<string[]>;
  public filteredCity: Observable<any[]>;
  public filteredCompanies: Observable<Company[]>;
  public filteredGsa: Observable<Company[]>;
  airlineNameTimer: NodeJS.Timeout;
  airportTimer: NodeJS.Timeout;
  countryTimer: NodeJS.Timeout;
  cityTimer: NodeJS.Timeout;
  company: Company = null;
  intlTelInput: any;
  isVideoClosed = false;
  prefillInformation: any;
  citiesOptions = [];
  selectedCity: ICity;

  referrer: IReferrer;

  get isCompanyNew(): boolean {
    return (
      this.registerForm.controls.fullCompanyName.value &&
      typeof this.registerForm.controls.fullCompanyName.value === 'string' &&
      this.registerForm.controls.fullCompanyName.value.replace(/\s/g, '')
        .length > 0
    );
  }
  get isFullCompanyNameInUse(): boolean {
    if (
      this.registerForm.controls.fullCompanyName.value &&
      typeof this.registerForm.controls.fullCompanyName.value === 'string'
    ) {
      const findCompany = this.allCompanies.find(
        (c) =>
          c.companyCode.replace(/\s/g, '').toLowerCase() ===
          this.registerForm.controls.fullCompanyName.value
            .replace(/\s/g, '')
            .toLowerCase(),
      );
      if (findCompany) {
        this.registerForm.controls.fullCompanyName.setErrors({
          inUse: true,
        });
        return true;
      }
    }
    return false;
  }

  get isGSANew(): boolean {
    return (
      this.registerForm.controls.GSACompanyName.value &&
      typeof this.registerForm.controls.GSACompanyName.value === 'string' &&
      this.registerForm.controls.GSACompanyName.value.replace(/\s/g, '')
        .length > 0
    );
  }

  get isGSACompanyNameInUse(): boolean {
    if (
      this.registerForm.controls.GSACompanyName.value &&
      typeof this.registerForm.controls.GSACompanyName.value === 'string'
    ) {
      const findCompany = this.allCompanies.find(
        (c) =>
          c.companyCode.replace(/\s/g, '').toLowerCase() ===
          this.registerForm.controls.GSACompanyName.value
            .replace(/\s/g, '')
            .toLowerCase(),
      );
      if (findCompany) {
        this.registerForm.controls.GSACompanyName.setErrors({ inUse: true });
        return true;
      }
    }
    return false;
  }

  public get UserTypeEnum() {
    return UserTypeEnum;
  }

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    public readonly titleService: Title,
    private readonly sessionService: SessionService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly registerService: RegisterService,
    private readonly airportService: AirportService,
    private readonly companyService: CompanyService,
    private readonly referralService: ReferralService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly intercomService: IntercomService,
    private readonly cityService: CityService,
  ) {}

  private async initIntlTelInput(): Promise<void> {
    const input = document.querySelector('#phone') as HTMLInputElement;
    input.classList.add('phone-input');
    this.intlTelInput = intlTelInput(input, {
      separateDialCode: true,
      utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@24.1.0/build/js/utils.js',
    });
    await this.intlTelInput.promise;
    this.isFormOpened = true;
    this.cdr.detectChanges();
  }

  @HostListener('countrychange', ['$event']) countryChange(e: MouseEvent) {
    e.stopPropagation();
    this.setPhoneMask();
    this.registerForm.controls.phone.updateValueAndValidity();
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      if (params.referral) {
        this.referrer = this.referralService.getReferrerFromReferralCode(
          params.referral,
        );
      }
    });
    const cities = await this.cityService.getCities();
    this.citiesOptions = cities.map((city) => ({
      ...city,
      label: city.cityName,
      value: city.cityId,
    }));
    const typeRegexp = /\/register\/([^?/]+)/,
      url = this.router.url,
      match = typeRegexp.exec(url);
    this.userType = UserTypeEnum[match[1].toUpperCase()];
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      this.registerService.validateEmail(this.token).then();
    }
    try {
      this.user = (await this.registerService.getUser(this.token)) as Forwarder;
    } catch (e) {
      this.router.navigateByUrl(`/register/invalid?token=${this.token}`);
      return;
    }
    if (this.user.userStatus === UserStatusEnum.ACTIVATED) {
      Swal.fire(
        '',
        $localize`:@@registration.user.already-activated:Your account is already activated, please login.`,
        'warning',
      ).then(() => {
        this.router.navigateByUrl('/auth/login');
        return;
      });
    }
    if (this.user.userStatus === UserStatusEnum.DELETED) {
      Swal.fire(
        '',
        $localize`:@@registration.user.already-expired:Your account activation has expired, please restart.`,
        'warning',
      ).then(() => {
        this.router.navigateByUrl('/register');
        return;
      });
    }
    this.onResize();

    this.titleService.setTitle(
      $localize`:@@registration.complete.title-confirm-registration:Confirm Registration` +
        ` - ${BrandIconUtil.fetchDomainTitle()}`,
    );
    this.allCompanies = (await this.companyService.getCompanies(
      null,
      false,
      this.token,
    )) as Company[];
    this.airports = await this.airportService.getAirportsLight();
    this.countries = this.airports
      .map((airport) => airport.countryCode)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.initRegisterForm();
    this.initFilteredAirports();
    this.filteredCountries =
      this.registerForm.controls.country.valueChanges.pipe(
        startWith(''),
        map((name: string) =>
          name ? this.filterCountries(name) : this.countries.slice(),
        ),
      );
    this.filteredCity = this.registerForm.controls.city?.valueChanges.pipe(
      startWith(''),
      map((city: string) =>
        city ? this.filterCity(city) : this.citiesOptions.slice(),
      ),
    );
    if (
      this.userType === UserTypeEnum.FORWARDER ||
      this.userType === UserTypeEnum.STANDARD
    ) {
      this.companies = this.allCompanies.filter(
        (company) =>
          company.companyType === this.userType.toString() ||
          this.userType === UserTypeEnum.STANDARD,
      );
      this.filteredCompanies =
        this.registerForm.controls.fullCompanyName.valueChanges.pipe(
          startWith(''),
          map((value: string | Company) =>
            typeof value === 'string' ? value : value ? value.companyName : '',
          ),
          map((name: string) => (name ? name.replace(/\s/g, '') : '')),
          map((name: string) =>
            name.length >= COMPANIES_AUTOCOMPLETE_MIN_LENGTH
              ? this.filterCompanies(name)
              : [],
          ),
        );
    }
    if (this.userType === UserTypeEnum.AIRLINE) {
      this.gsa = this.allCompanies.filter(
        (company) => company.companyType === 'GSA',
      );
      this.companies = this.allCompanies.filter(
        (company) => company.companyType === 'AIRLINE',
      );

      this.registerForm
        .get('airlineName')
        .setValidators([
          AutoCompleteValidators.mustBeInList(this.companies, 'companyName'),
          this.registerForm.get('airlineName').validator,
        ]);
      this.filteredCompanies =
        this.registerForm.controls.airlineName.valueChanges.pipe(
          startWith(''),
          map((value) => {
            if (!value) {
              return '';
            }
            return typeof value === 'string' ? value : value.airlineCompanyName;
          }),
          map((name: string) => (name ? name.replace(/\s/g, '') : '')),
          map((name: string) =>
            name.length >= COMPANIES_AUTOCOMPLETE_MIN_LENGTH
              ? this.filterCompanies(name)
              : [],
          ),
        );
      this.filteredGsa =
        this.registerForm.controls.GSACompanyName.valueChanges.pipe(
          startWith(''),
          map((value: string | Company) =>
            typeof value === 'string' ? value : value ? value.companyName : '',
          ),
          map((name: string) => (name ? name.replace(/\s/g, '') : '')),
          map((name: string) =>
            name.length >= COMPANIES_AUTOCOMPLETE_MIN_LENGTH
              ? this.filterGSA(name)
              : [],
          ),
        );
    }
    this.cdr.detectChanges();
    await this.initIntlTelInput();
    if (
      this.userType === UserTypeEnum.FORWARDER ||
      this.userType === UserTypeEnum.STANDARD
    ) {
      await this.getPrefilledInformationOnUser();
    }
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParamMap.get('success') == 'true') {
      this.success = true;
    }
  }
  removeCompany(): void {
    this.company = null;
    this.fullCompanyNameInput.nativeElement.value = '';
    this.registerForm.controls.fullCompanyName.setValue(null);
  }

  removeAirline(): void {
    this.company = null;
    this.airlineNameInput.nativeElement.value = '';
    this.registerForm.controls.airlineName.setValue('');
  }
  get isNotAllowedStandardCompany(): boolean {
    if (!this.company) {
      return false;
    }
    return (
      this.userType === UserTypeEnum.STANDARD &&
      this.company.companyType !== UserTypeEnum.STANDARD.toString()
    );
  }
  async getPrefilledInformationOnUser() {
    // Prefil with token
    const prefillInformation$ = this.registerService.getPrefillInformation(
      this.token,
    );
    this.prefillInformation = await lastValueFrom(prefillInformation$);
    const forwarder = this.user as Forwarder;
    if (this.prefillInformation) {
      forwarder.iataCode = this.prefillInformation.iata;
      forwarder.cassCode = this.prefillInformation.cass;

      if (this.prefillInformation.company) {
        this.company = this.prefillInformation.company;
        this.registerForm.controls.fullCompanyName.setValue(this.company);
      }

      if (
        this.prefillInformation.airport &&
        this.userType !== UserTypeEnum.STANDARD
      ) {
        this.registerForm.controls.airportCode.setValue(
          this.prefillInformation.airport?.airportCode,
        );
        this.onAirportChanged(this.prefillInformation.airport?.airportCode);
      }
    }
    // Prefill with user saved values
    if (
      this.user?.company &&
      this.user?.originAirport &&
      this.user?.country &&
      this.user?.contact
    ) {
      this.company = this.user.company;
      const airportFound = this.airports.find(
        (airport) =>
          airport?.airportCode.toLowerCase() ===
          this.user.originAirport.airportCode.toLowerCase(),
      );
      this.registerForm.controls.airportCode.setValue(airportFound);
      this.registerForm.controls.country.setValue(
        this.user.country.countryCode,
      );
      this.intlTelInput?.setCountry(this.user.country.countryCode);
      this.registerForm.controls.phone.setValue(
        this.user.contact.phoneNumber.substring(3),
      );
      this.onPhoneChanged();
    }

    this.cdr.detectChanges();
  }

  onIsGSAChanged(): void {
    if (this.registerForm.controls.isGSA.value) {
      this.companyService
        .getCompanies('AIRLINE', true, this.token)
        .then((res) => {
          this.companies = res as Company[];
          if (
            this.company &&
            !this.companies.find(
              (c) => c.companyName === this.company.companyName,
            )
          ) {
            this.removeAirline();
          }
          this.registerForm.controls.GSACompanyName.setValidators(
            Validators.compose([
              LoginFormValidators.required('GSA Company Name'),
              LoginFormValidators.minLength(2, 'GSA Company Name'),
              LoginFormValidators.maxLength(50),
            ]),
          );
          this.registerForm.controls.GSACompanyName.updateValueAndValidity();
          this.cdr.detectChanges();
        });
    } else {
      this.companyService
        .getCompanies('AIRLINE', false, this.token)
        .then((res) => {
          this.companies = res as Company[];
          this.registerForm.controls.GSACompanyName.setValidators(
            Validators.compose([]),
          );
          this.registerForm.controls.GSACompanyName.updateValueAndValidity();
          this.cdr.detectChanges();
        });
    }
  }
  initFilteredAirports(): void {
    this.filteredAirports =
      this.registerForm.controls.airportCode.valueChanges.pipe(
        startWith(''),
        switchMap((name: string) => {
          const selectedCountry = this.registerForm.controls.country.value;

          if (name?.length >= AIRPORTS_AUTOCOMPLETE_MIN_LENGTH) {
            const filteredAirports = selectedCountry
              ? this.filterAirports(name).filter(
                  (airport) => airport.countryCode === selectedCountry,
                )
              : this.filterAirports(name);

            return of(filteredAirports as AirportLight[]);
          } else {
            const filteredAirports = selectedCountry
              ? this.airports.filter(
                  (airport) => airport.countryCode === selectedCountry,
                )
              : this.airports;

            return of(filteredAirports as AirportLight[]);
          }
        }),
      );
  }

  displayAirportsFn(airport: AirportLight): string {
    return airport?.airportCode ? `${airport?.airportCode}` : '';
  }

  displayCountriesFn(country: string): string {
    return country;
  }
  displayCityFn(city: string): string {
    return city;
  }

  displayCompaniesFn(company: Company): string {
    if (!company) {
      return '';
    }
    return company.companyName;
  }

  onRegisterClick(): void {
    if (this.shouldRegisterBtnDisabled) {
      this.isFormValid();
      return;
    }
    if (
      this.userType === UserTypeEnum.FORWARDER ||
      this.userType === UserTypeEnum.STANDARD
    ) {
      this.updateForwarder();
    } else if (this.userType === UserTypeEnum.AIRLINE) {
      this.updateAirline();
    }
  }

  updateAirline(): void {
    const airline = this.user as AirlineUser;
    if (this.registerForm.controls.isGSA.value) {
      if (this.registerForm.controls.GSACompanyName.value.id) {
        airline.company = this.registerForm.controls.GSACompanyName.value;
      } else {
        airline.company = this.gsa.find(
          (gsaCompany) =>
            gsaCompany.companyName.toLowerCase() ===
            this.registerForm.controls.GSACompanyName.value
              .trim()
              .toLowerCase(),
        );
      }
      if (!airline.company) {
        airline.company = new Company();
        airline.company.companyCode =
          this.registerForm.controls.GSACompanyName.value.trim();
        airline.company.companyName =
          this.registerForm.controls.GSACompanyName.value.trim();
        airline.company.companyType = CompanyTypeEnum.GSA;
      }
    } else {
      airline.company = this.company;
    }
    airline.country = new Country();
    airline.country.countryCode = this.registerForm.controls.country.value;
    airline.contact = new Contact();
    airline.contact.countryCode =
      this.intlTelInput.getSelectedCountryData().iso2;
    airline.contact.phoneNumber = this.intlTelInput.getNumber(
      IntlTelFormatEnum.INTERNATIONAL,
    );
    airline.originAirport = this.registerForm.controls.airportCode.value;
    airline.measurementSystem = MeasurementSystemEnum.METRIC;
    if (airline.country.countryCode === 'US') {
      airline.measurementSystem = MeasurementSystemEnum.IMPERIAL;
    }
    this.isLoading = true;
    this.cdr.detectChanges();
    const callTime = Date.now() / 1000;
    this.registerService
      .updateAirline(
        airline,
        this.registerForm.controls.isGSA.value
          ? this.company.companyCode
          : null,
        this.token,
      )
      .subscribe({
        next: (airlineUser) => {
          this.sessionService.setCurrentUser(airlineUser);
          this.updateIntercomData(airlineUser);
          if (airlineUser.userStatus === UserStatusEnum.ACTIVATED) {
            this.isActivated = true;
          }
          this.success = true;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              success: 'true',
            },
            queryParamsHandling: 'merge',
            // preserve the existing query params in the route
            skipLocationChange: false,
            // do not trigger navigation
          });
          this.cdr.detectChanges();
        },
        error: (e) => {
          console.error('cannot updateAirline: ', e);
          Swal.fire(
            '',
            $localize`:@@registration.user.error:Something went wrong while registering, please contact support`,
            'error',
          );
        },
        complete: () => {
          this.loadingBuffer(callTime);
        },
      });
  }

  loadingBuffer(callTime: number): void {
    const endTime = Date.now() / 1000;
    if (endTime - callTime < LOADING_BUFFER_TIME_IN_SEC) {
      delay(LOADING_BUFFER_TIME_IN_SEC - (endTime - callTime) * 1000);
    }
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  updateForwarder(): void {
    const forwarder = this.user as Forwarder,
      company: string | Company =
        this.registerForm.controls.fullCompanyName.value;
    if (company !== '' && typeof company === 'string') {
      this.user.company = new Company();
      this.user.company.companyName = company;
      this.user.company.companyCode = company;
      this.user.company.companyType = this.userType;
    } else {
      this.user.company = this.company;
    }
    forwarder.userStatus = UserStatusEnum.PENDING_COMPANY_INFORMATION;
    forwarder.country = new Country();
    forwarder.country.countryCode = this.registerForm.controls.country.value;
    forwarder.contact = new Contact();
    forwarder.city = this.selectedCity;
    forwarder.contact.countryCode =
      this.intlTelInput.getSelectedCountryData().iso2;
    forwarder.contact.phoneNumber = this.intlTelInput.getNumber(
      IntlTelFormatEnum.INTERNATIONAL,
    );
    forwarder.measurementSystem = MeasurementSystemEnum.METRIC;
    if (forwarder.country.countryCode === 'US') {
      forwarder.measurementSystem = MeasurementSystemEnum.IMPERIAL;
    }
    if (this.userType !== UserTypeEnum.STANDARD) {
      forwarder.originAirport = this.registerForm.controls.airportCode.value;
    } else {
      forwarder.company.subType =
        CompanySubTypeEnum[this.user?.subType?.toString()];
    }
    this.isLoading = true;
    this.cdr.detectChanges();
    const callTime = Date.now() / 1000;
    let registrSubscriber$;
    if (this.userType === UserTypeEnum.FORWARDER) {
      registrSubscriber$ = this.registerService.updateForwarder(
        forwarder,
        this.token,
      );
    } else {
      registrSubscriber$ = this.registerService.updateStandard(
        forwarder,
        this.token,
      );
    }
    registrSubscriber$.subscribe({
      next: (forwarderUser) => {
        this.updateIntercomData(forwarderUser);
        this.sessionService.setCurrentUser(forwarderUser);
        if (
          forwarderUser.userStatus ===
            UserStatusEnum.PENDING_MANUAL_ACTIVATION &&
          this.userType === UserTypeEnum.STANDARD
        ) {
          this.success = true;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              success: 'true',
            },
            queryParamsHandling: 'merge',
            // preserve the existing query params in the route
            skipLocationChange: false,
            // do not trigger navigation
          });
          this.cdr.detectChanges();
          return;
        }
        if (forwarderUser.userStatus === UserStatusEnum.ACTIVATED) {
          this.success = true;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              success: 'true',
            },
            queryParamsHandling: 'merge',
            // preserve the existing query params in the route
            skipLocationChange: false,
            // do not trigger navigation
          });
        } else {
          this.router.navigateByUrl(
            `/register/forwarder/info?token=${this.token}`,
          );
        }
        this.cdr.detectChanges();
      },
      error: (e) => {
        console.error('cannot updateForwarder: ', e);
        Swal.fire(
          '',
          $localize`:@@registration.user.error:Something went wrong while registering, please contact support`,
          'error',
        );
      },
      complete: () => {
        this.loadingBuffer(callTime);
      },
    });
  }

  onNextClick(): void {
    if (this.userType === UserTypeEnum.FORWARDER) {
      this.router.navigateByUrl(
        `/register/forwarder/iata-cass?token=${this.token}`,
      );
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }

  public onSelectCompany(event: MatAutocompleteSelectedEvent) {
    this.company = this.companies.find(
      (company) => company.companyName === event.option.viewValue,
    );
    this.fullCompanyNameInput.nativeElement.value = '';
    this.registerForm.controls.fullCompanyName.setValue(this.company);
    this.cdr.detectChanges();
  }

  public onSelectAirline(event: MatAutocompleteSelectedEvent) {
    this.company = this.companies.find(
      (company) => company.companyName === event.option.viewValue,
    );
    this.airlineNameInput.nativeElement.value = '';
    this.registerForm.controls.airlineName.setValue(null);
    this.cdr.detectChanges();
  }

  public onFullCompanyNameFocusOut() {
    const company: string | Company =
      this.registerForm.controls.fullCompanyName.value;
    if (typeof company === 'string') {
      const companyLowerCase: string = company.replace(/\s/g, '').toLowerCase(),
        matchingCompanies = this.companies.filter(
          (c) =>
            c.companyName.replace(/\s/g, '').toLowerCase() === companyLowerCase,
        );
      if (matchingCompanies.length === 1) {
        this.registerForm.controls.fullCompanyName.setValue(
          matchingCompanies[0],
        );
        this.company = matchingCompanies[0];
      }
    }
  }

  public onGSACompanyNameFocusOut() {
    const company: string | Company =
      this.registerForm.controls.GSACompanyName.value;
    if (typeof company === 'string') {
      const companyLowerCase: string = company.replace(/\s/g, '').toLowerCase();
      if (companyLowerCase.length === 0) {
        this.registerForm.controls.GSACompanyName.setValue('');
        return;
      }
      const matchingCompanies = this.gsa.filter(
        (c) =>
          c.companyName.replace(/\s/g, '').toLowerCase() === companyLowerCase,
      );
      if (matchingCompanies.length === 1) {
        this.registerForm.controls.GSACompanyName.setValue(
          matchingCompanies[0],
        );
      }
    }
  }

  private filterAirports(value: string): AirportLight[] {
    const filterValue = value.toLowerCase();
    return isNaN(+filterValue)
      ? this.airports.filter(
          (airport) =>
            airport?.airportCode.toLowerCase().indexOf(filterValue) === 0 ||
            airport.airportName.toLowerCase().indexOf(filterValue) === 0,
        )
      : [];
  }

  private filterCountries(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.countries.filter(
      (country) => country.toLowerCase().indexOf(filterValue) === 0,
    );
  }
  private filterCity(value: string): string[] {
    try {
      const filterValue = value?.toLowerCase();
      return [...this.citiesOptions].filter(
        (city) => city.cityName.toLowerCase().indexOf(filterValue) === 0,
      );
    } catch (error) {
      return [...this.citiesOptions];
    }
  }

  private filterCompanies(value: string): Company[] {
    const filterValue = value.toLowerCase();
    return this.companies.filter(
      (company) =>
        company.companyName &&
        company.companyName
          .replace(/\s/g, '')
          .toLowerCase()
          .indexOf(filterValue) >= 0,
    );
  }

  private filterGSA(value: string): Company[] {
    const filterValue = value.toLowerCase();
    return this.gsa.filter(
      (company) =>
        company.companyName &&
        company.companyName
          .replace(/\s/g, '')
          .toLowerCase()
          .indexOf(filterValue) >= 0,
    );
  }

  updateIntercomData(user: User) {
    let userType = UserTypeEnum.FORWARDER;
    if (this.userType === UserTypeEnum.AIRLINE) {
      if (this.registerForm.controls.isGSA.value) {
        userType = UserTypeEnum.GSA;
      } else {
        userType = UserTypeEnum.AIRLINE;
      }
    }
    const intercomSetting = {
      app_id: environment.intercomAppId,
      email: user.userEmail,
      cargoai_user_id: user.userId,
      name: user.userName,
      Account_Activation_status: user.userStatus,
      full_company_name: this.user.company.companyName,
      Customer_type: userType,
      Country: user.country.countryName,
      origin_airport_code: user.originAirport?.airportCode,
      subscription_status: SubscriptionEnum.INACTIVE,
      GSA_Airline_name: this.registerForm.controls.GSACompanyName
        ? this.registerForm.controls.GSACompanyName.value
        : null,
    };
    this.intercomService.update(intercomSetting);
  }

  onAirportChanged(airportValue: string): void {
    clearTimeout(this.airportTimer);
    const airportFound = this.airports.find(
      (airport) =>
        airport?.airportCode.toLowerCase() === airportValue.toLowerCase(),
    );
    if (airportFound) {
      this.registerForm.controls.airportCode.setValue(airportFound);
      this.registerForm.controls.country.setValue(airportFound.countryCode);
      this.intlTelInput.setCountry(airportFound.countryCode);
      this.onPhoneChanged();
    } else {
      this.registerForm.controls.airportCode.setValue('');
    }
    this.cdr.detectChanges();
  }

  onCountryChanged(countryValue: string): void {
    clearTimeout(this.countryTimer);
    const countryFound = this.countries.find(
      (country) => country.toLowerCase() === countryValue.toLowerCase(),
    );
    if (countryFound) {
      this.registerForm.controls.country.setValue(countryFound);
      this.intlTelInput.setCountry(countryFound);
      //this.onPhoneChanged();
      const airportControl = this.registerForm.controls.airportCode;
      if (
        airportControl.value &&
        airportControl.value.countryCode !== countryFound
      ) {
        airportControl.setValue(null);
      }
    } else {
      this.registerForm.controls.country.setValue('');
    }
    this.initFilteredAirports();
    this.cdr.detectChanges();
  }

  onPhoneChanged() {
    this.setPhoneMask();
    this.registerForm.controls.phone.updateValueAndValidity();
  }
  onCityFocusOut(e: Event) {
    const target = e.target as HTMLInputElement,
      cityValue = target.value;
    this.cityTimer = setTimeout(() => this.onCityChanged(cityValue), 300);
  }
  onCityChanged(cityName: string): void {
    clearTimeout(this.cityTimer);
    const cityFound = this.citiesOptions.find(
      (city) => city.cityName.toLowerCase() === cityName.toLowerCase(),
    );
    this.selectedCity = cityFound;
    if (cityFound) {
      this.registerForm.controls.city.setValue(cityFound.cityName);
    } else {
      this.registerForm.controls.city.setValue('');
    }
    this.cdr.detectChanges();
  }
  setPhoneMask() {
    const input = document.querySelector('#phone');
    this.phoneMask = input?.getAttribute('placeholder');
  }

  onAirportFocusOut(e: Event) {
    const target = e.target as HTMLInputElement,
      airportValue = target.value;
    this.airportTimer = setTimeout(
      () => this.onAirportChanged(airportValue),
      300,
    );
  }

  onCountryFocusOut(e: Event) {
    const target = e.target as HTMLInputElement,
      countryValue = target.value;
    this.countryTimer = setTimeout(
      () => this.onCountryChanged(countryValue),
      300,
    );
  }

  changeAirline(airlineValue: any) {
    clearTimeout(this.airlineNameTimer);
    this.company = this.companies.find(
      (company) =>
        company.companyName.toLowerCase() === airlineValue.toLowerCase(),
    );
    if (this.company) {
      this.registerForm.controls.airlineName.setValue(this.company);
    } else {
      this.registerForm.controls.airlineName.setValue('');
    }
    this.cdr.detectChanges();
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      !this.intlTelInput || this.intlTelInput.isValidNumber()
        ? null
        : { phoneInvalid: { value: control.value } };
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    if (this.userType === UserTypeEnum.AIRLINE) {
      this.initRegisterFormAirline();
    } else if (this.userType === UserTypeEnum.FORWARDER) {
      this.initRegisterFormForwarder();
    } else if (this.userType === UserTypeEnum.STANDARD) {
      this.initRegisterFormStandard();
    }
  }

  isFormValid(): boolean {
    if (this.registerForm.invalid) {
      Object.keys(this.registerForm.controls).forEach((key) => {
        const control = this.registerForm.controls[key];
        if (control && control.invalid) {
          control.markAsTouched();
          if (control.value === undefined || control.value === null) {
            control.setValue(null);
          }
        }
      });
      return false;
    }
    return true;
  }

  initRegisterFormAirline() {
    this.registerForm = this.fb.group({
      airlineName: [
        '',
        Validators.compose([
          LoginFormValidators.required('Airline Name'),
          LoginFormValidators.minLength(3, 'Airport Name'),
          LoginFormValidators.maxLength(320),
          LoginFormValidators.pattern('.*[^ ].*'),
        ]),
      ],
      isGSA: [''],
      phone: [
        '',
        Validators.compose([
          LoginFormValidators.required('Phone number'),
          this.phoneValidator(),
        ]),
      ],
      GSACompanyName: ['', Validators.compose([])],
      airportCode: [
        '',
        Validators.compose([
          LoginFormValidators.required(AIRPORT_CODE),
          LoginFormValidators.minLength(2, AIRPORT_CODE),
          LoginFormValidators.maxLength(50),
        ]),
      ],
      country: [
        '',
        Validators.compose([
          LoginFormValidators.required('Country'),
          LoginFormValidators.minLength(2, 'Country'),
          LoginFormValidators.maxLength(50),
        ]),
      ],
    });
  }

  initRegisterFormForwarder() {
    this.registerForm = this.fb.group({
      fullCompanyName: [
        '',
        Validators.compose([
          LoginFormValidators.required('Full Company Name'),
          LoginFormValidators.minLength(3, 'Full Company Name'),
          LoginFormValidators.maxLength(320),
          LoginFormValidators.pattern('.*[^ ].*'),
        ]),
      ],
      airportCode: [
        '',
        Validators.compose([
          LoginFormValidators.required(AIRPORT_CODE),
          LoginFormValidators.minLength(2, AIRPORT_CODE),
          LoginFormValidators.maxLength(50),
        ]),
      ],
      phone: [
        '',
        Validators.compose([
          LoginFormValidators.required('Phone number'),
          this.phoneValidator(),
        ]),
      ],
      country: [
        '',
        Validators.compose([
          LoginFormValidators.required('Country'),
          LoginFormValidators.minLength(2, 'Country'),
          LoginFormValidators.maxLength(50),
        ]),
      ],
      city: [
        '',
        Validators.compose([
          LoginFormValidators.required('City'),
          LoginFormValidators.minLength(1, 'City'),
        ]),
      ],
    });
  }
  initRegisterFormStandard() {
    this.registerForm = this.fb.group({
      fullCompanyName: [
        '',
        Validators.compose([
          LoginFormValidators.required('Full Company Name'),
          LoginFormValidators.minLength(3, 'Full Company Name'),
          LoginFormValidators.maxLength(320),
          LoginFormValidators.pattern('.*[^ ].*'),
        ]),
      ],
      airportCode: [''],
      phone: [
        '',
        Validators.compose([
          LoginFormValidators.required('Phone number'),
          this.phoneValidator(),
        ]),
      ],
      country: [
        '',
        Validators.compose([
          LoginFormValidators.required('Country'),
          LoginFormValidators.minLength(2, 'Country'),
          LoginFormValidators.maxLength(50),
        ]),
      ],
      city: [
        '',
        Validators.compose([
          LoginFormValidators.required('City'),
          LoginFormValidators.minLength(1, 'City'),
        ]),
      ],
    });
  }

  get shouldRegisterBtnDisabled(): boolean {
    const validateAirportCode =
      this.userType === UserTypeEnum.STANDARD
        ? false
        : this.registerForm?.controls?.airportCode?.value?.countryCode !==
          this.registerForm?.controls?.country?.value;
    return (
      !this.registerForm ||
      this.registerForm.invalid ||
      validateAirportCode ||
      (!this.company &&
        (((this.userType === UserTypeEnum.FORWARDER ||
          this.userType === UserTypeEnum.STANDARD) &&
          !this.registerForm?.controls.fullCompanyName.value) ||
          (this.userType !== UserTypeEnum.FORWARDER &&
            this.userType !== UserTypeEnum.STANDARD)))
    );
  }

  isControlHasError(
    form: FormGroup,
    controlName: string,
    validationType: string,
  ): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  closeVideo() {
    this.isVideoClosed = true;
    this.cdr.detectChanges();
  }

  onResize() {
    this.isVideoClosed = window.innerWidth < 1250;
    this.cdr.detectChanges();
  }

  errorMessage(controlName): string {
    const control = this.registerForm?.controls[controlName];
    if (control?.errors && (control.touched || control.dirty)) {
      const error = Object.values(control.errors)[0]?.message || null;
      if (error) {
        return error;
      }
      if (control.errors?.phoneInvalid) {
        return 'Please enter a valid phone number';
      }
      if (
        (controlName === 'GSACompanyName' ||
          controlName === 'fullCompanyName') &&
        control?.errors.inUse
      ) {
        return 'This company name is taken, try a different name.';
      }
      return null;
    }
    return null;
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      '/assets/media/logos/',
    );
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }
}
