// Angular
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CargoMartPlansType, UserTypeEnum } from '@cai-services';
import { datadogRum } from '@datadog/browser-rum';
import { Subscription } from 'rxjs';
import { APP_PROPS } from '../../cai-common.module';
import { HtmlClassService } from '../../core/_base/crud/utils/html-class.service';
import { ApplicationProperties } from '../../core/_base/layout/models/app-properties.model';
import { SessionService } from '../../core/_services/session.service';
import { HomepageCommonUtil, WalletUtil } from '../../utils';
import { BrandIconUtil } from '../../utils/brand.util';
import { MenuHorizontalService, User, UserFeaturesEnum } from '../../core';
import { environment } from '../../environments';
import { ProGenericModal } from '../../components/office-settings/modals/pro-generic-modal/pro-generic-modal.component';

@Component({
  selector: 'kt-brand',
  styleUrls: ['./brand.component.scss'],
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, OnDestroy {
  // Public properties
  headerLogo: string;
  homeURL: string;
  user: User;
  isEmbedMode: boolean;
  shouldDisplayProPlan = false;
  private officeChangedStatusSub: Subscription;
  features = environment.features;
  upgradePopUpClosed = true;

  @HostBinding('class') classes = 'kt-header__brand kt-grid__item';

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private route: ActivatedRoute,
    public htmlClassService: HtmlClassService,
    private sessionService: SessionService,
    private readonly menuHorizontalService: MenuHorizontalService,
    private readonly modalService: NgbModal,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.headerLogo = this.appProperties.logo;
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.isEmbedMode = WalletUtil.isEmbedMode(this.appProperties, params);
    });
    await this.checkHomeUrl();
    this.sessionService.watchSessionStorage().subscribe((res) => {
      if (res.key === 'currentUser') {
        this.user = res.data;
        this.shouldDisplayProPlan =
          this.menuHorizontalService.shouldDisplayProLogo(this.user) &&
          this.isCargoMart;
      }
    });

    if (this.sessionService.isCargoMart) {
      this.officeChangedStatusSub =
        this.sessionService.officeChangedStatusObs.subscribe(async () => {
          this.shouldDisplayProPlan =
            this.menuHorizontalService.shouldDisplayProLogo(this.user) &&
            this.isCargoMart;
        });
      this.cdr.detectChanges();
    }

    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  ngOnDestroy() {
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  async checkHomeUrl() {
    const currentGroup = await this.sessionService.getCurrentUserGroup();
    if (!currentGroup) {
      return;
    }
    const userMenu = this.appProperties.menu.find((config) =>
      currentGroup.includes(config.userType.toLowerCase()),
    );
    if (userMenu) {
      const user = this.sessionService.getCurrentUser();
      if (
        user?.type === UserTypeEnum.AIRLINE &&
        !!user?.activatedFeatures?.find((af) => af.featureName === 'INTERLINE')
      ) {
        this.homeURL = userMenu.secondDefaultPage;
        return;
      }
      this.homeURL = userMenu.defaultPage;
    }
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.headerLogo,
      '/assets/media/logos/',
    );
  }

  handleLogoClick(): void {
    if (this.isEmbedMode) {
      return;
    }
    if (this.router.url.slice(1, this.router.url?.length) !== this.homeURL) {
      this.router.navigateByUrl(this.homeURL);
    }
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  get isCargoMartProEnabled(): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }

  showUpgradeMessage() {
    if (
      this.sessionService.getSelectedOffice()?.plan ===
        CargoMartPlansType.PRO ||
      this.sessionService.getCurrentUser()?.type === UserTypeEnum.AIRLINE
    ) {
      this.handleLogoClick();
      return;
    }
    if (!this.upgradePopUpClosed) {
      return;
    }
    this.upgradePopUpClosed = false;
    const branchesSelectorModal = this.modalService.open(ProGenericModal, {
      centered: true,
    });
    branchesSelectorModal.componentInstance.type = 'SUGGEST-UPGRADE';

    branchesSelectorModal.result.then(
      () => {
        this.upgradePopUpClosed = true;
      },
      () => {
        this.upgradePopUpClosed = true;
      },
    );
  }

  get otherBrand(): boolean {
    const url = window.location.href;
    return !url.includes('localhost') && !url.split('.')[0].includes('app');
  }
}
