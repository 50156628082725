// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Object-Path
import * as objectPath from 'object-path';
// HTML Class
import { CargoMartPlansType, UserTypeEnum } from '@cai-services';
import { datadogRum } from '@datadog/browser-rum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_PROPS } from '../../../cai-common.module';
import { ProGenericModal } from '../../../components/office-settings/modals/pro-generic-modal/pro-generic-modal.component';
import { UserFeaturesEnum } from '../../../core';
import { HtmlClassService } from '../../../core/_base/crud/utils/html-class.service';
import { MenuOptions } from '../../../core/_base/layout/directives/menu.directive';
import { OffcanvasOptions } from '../../../core/_base/layout/directives/offcanvas.directive';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';
import { LayoutConfigService } from '../../../core/_base/layout/services/layout-config.service';
import { MenuHorizontalService } from '../../../core/_base/layout/services/menu-horizontal.service';
import { SessionService } from '../../../core/_services/session.service';
import { environment } from '../../../environments';
import { HomepageCommonUtil } from '../../../utils';

@Component({
  selector: 'kt-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalComponent implements OnInit {
  // Public properties
  currentRouteUrl: any = '';
  isEmbedMode: boolean;
  rootArrowEnabled: boolean;
  asideDisplay: boolean;
  features = environment.features;
  upgradePopUpClosed = true;

  @Input() isWalletApp: boolean;

  private readonly dashboardAirlineHideableSubtitles: string[] = [
    $localize`:@@global.market-analysis:Market analysis`,
    $localize`:@@global.load-board:Load board`,
  ];

  menuOptions: MenuOptions = {
    submenu: {
      desktop: 'dropdown',
      tablet: 'accordion',
      mobile: 'accordion',
    },
    accordion: {
      slideSpeed: 200, // accordion toggle slide speed in milliseconds
      expandAll: false, // allow having multiple expanded accordions in the menu
    },
  };

  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'kt-header-menu-wrapper',
    closeBy: 'kt_header_menu_mobile_close_btn',
    toggleBy: {
      target: 'kt_header_mobile_toggler',
      state: 'kt-header-mobile__toolbar-toggler--active',
    },
  };

  martProMenus = [
    {
      title: $localize`:@@global.reports:Reports`,
      page: '/forwarder/dashboard/reports',
    },
    {
      title: $localize`:@@global.market-analysis:Market analysis`,
      page: '/forwarder/dashboard/market-analysis',
    },
    {
      title: $localize`:@@global.airline-contact:Airline contacts`,
      page: '/forwarder/dashboard/airline-contacts',
    },
    {
      title: $localize`:@@menu-config.shipper:Shipper`,
      page: '/forwarder/quote/shipper-quote',
    },
  ];

  ratesSubMenus = [
    {
      title: $localize`:@@global.compare-rates:Compare rates`,
      page: '/forwarder/compare-rates',
    },
    {
      title: $localize`:@@global.airlines-rate-sheet:Airline rate sheets`,
      page: '/forwarder/rate-sheet',
    },
  ];

  @HostBinding('class') classes = 'kt-header__topbar kt-grid__item';

  /**
   * Component Conctructor
   *
   * @param el: ElementRef
   * @param htmlClassService: HtmlClassService
   * @param menuHorService: MenuHorService
   * @param menuConfigService: MenuConfigService
   * @param layoutConfigService: LayouConfigService
   * @param router: Router
   * @param render: Renderer2
   * @param cdr: ChangeDetectorRef
   */
  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    public readonly htmlClassService: HtmlClassService,
    public readonly menuHorService: MenuHorizontalService,
    private readonly layoutConfigService: LayoutConfigService,
    private readonly router: Router,
    private readonly render: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly sessionService: SessionService,
    private readonly modalService: NgbModal,
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.addDatadogFlagEvaluations();
    this.rootArrowEnabled = this.layoutConfigService.getConfig(
      'header.menu.self.root-arrow',
    );
    this.asideDisplay =
      this.layoutConfigService.getConfig('aside.self.display');

    this.currentRouteUrl = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRouteUrl = this.router.url;
        this.cdr.markForCheck();
      });
    if (
      this.sessionService.getCurrentUser()?.type.toUpperCase() ===
      UserTypeEnum.FORWARDER
    ) {
      this.disableForwarderSubMenus();
      this.sessionService.officeChangedStatusObs.subscribe(async () => {
        this.disableForwarderSubMenus();
      });
      this.sessionService.watchSessionStorage().subscribe((res) => {
        if (res.key === 'currentUser') {
          this.disableForwarderSubMenus();
        }
      });
    }
  }

  addDatadogFlagEvaluations() {
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.RATE_MANAGEMENT_ACCESS,
        this.isFeatureActivatedForSelectedOffice(
          UserFeaturesEnum.RATE_MANAGEMENT_ACCESS,
        ),
      );

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.RATE_MANAGEMENT_UPLOAD,
        this.isFeatureActivatedForSelectedOffice(
          UserFeaturesEnum.RATE_MANAGEMENT_UPLOAD,
        ),
      );
      if (
        this.sessionService.getCurrentUser()?.type.toUpperCase() ===
        UserTypeEnum.AIRLINE
      ) {
        datadogRum.addFeatureFlagEvaluation(
          UserFeaturesEnum.ANALYTICS_AIRLINE,
          this.isFeatureActivatedForSelectedOffice(
            UserFeaturesEnum.ANALYTICS_AIRLINE,
          ),
        );
      }

      if (
        this.sessionService.getCurrentUser()?.type.toUpperCase() ===
        UserTypeEnum.FORWARDER
      ) {
        datadogRum.addFeatureFlagEvaluation(
          UserFeaturesEnum.ANALYTICS_FORWARDER,
          this.isFeatureActivatedForSelectedOffice(
            UserFeaturesEnum.ANALYTICS_FORWARDER,
          ),
        );
      }

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  closeSideMenu(): void {
    document.getElementById('kt_header_menu_mobile_close_btn').click();
  }
  shouldDisplayRates(title: string): boolean {
    const user = this.sessionService.getCurrentUser();
    if (
      user?.company?.providers?.length > 0 &&
      title === $localize`:@@global.rates:Rates` &&
      (user?.company?.providers?.[0] !== 'DHL' ||
        user?.userEmail?.startsWith('tests+'))
    ) {
      return false;
    }
    return true;
  }

  shouldShowDashboard(title: string, subTitle: string): boolean {
    // For Airlines, show all 'Dashboard' menus if user is 'Pro'. If not 'Pro', hide certain menus.
    if (
      this.sessionService.getCurrentUser()?.type.toUpperCase() ===
      UserTypeEnum.AIRLINE
    ) {
      if (
        title === $localize`:@@global.dashboards:Dashboards` &&
        this.dashboardAirlineHideableSubtitles?.includes(subTitle) &&
        !this.isAnalyticalDashboardActivatedAirline
      ) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  async updateSubMenu(
    menu: MenuHorizontalService,
    featureEnum: UserFeaturesEnum,
    targetTitle: string,
    mainTitle: string,
  ): Promise<void> {
    const isFeatureActivated =
      this.isFeatureActivatedForSelectedOffice(featureEnum);

    menu.menuList$
      ?.getValue()
      ?.find((menu) => menu?.title === mainTitle)
      ?.submenu?.map((submenu) => {
        if (submenu.title === targetTitle) {
          const officeId = this.sessionService.getSelectedOfficeId(),
            office = this.sessionService
              .getCurrentUser()
              ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office;

          if (office?.plan === CargoMartPlansType.PRO) {
            if (isFeatureActivated) {
              // Ensure submenu is active and points to the right page
              const submenuToReactivate = this.martProMenus.find(
                (sb) => sb.title == targetTitle,
              );
              submenu.page = submenuToReactivate?.page;
              submenu.proTag = true;
              submenu.addonsTag = false;
            } else {
              submenu.page = null;
              submenu.proTag = false;
              submenu.addonsTag = true;
            }
          } else {
            // Office is not on a Pro plan
            submenu.page = null;
            submenu.proTag = true;
            submenu.addonsTag = false;
          }
        }
      });
  }

  async disableForwarderSubMenus() {
    if (this.isCargoMartProEnabled) {
      const menu = this.menuHorService;

      const isRateManagementActivated =
        this.isFeatureActivatedForSelectedOffice(
          UserFeaturesEnum.RATE_MANAGEMENT_ACCESS,
        ) &&
        this.isFeatureActivatedForSelectedOffice(
          UserFeaturesEnum.RATE_MANAGEMENT_UPLOAD,
        );

      menu.menuList$
        ?.getValue()
        ?.find((menu) => menu?.title === $localize`:@@global.rates:Rates`)
        ?.submenu?.map((submenu) => {
          const officeId = this.sessionService.getSelectedOfficeId(),
            office = this.sessionService
              .getCurrentUser()
              ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office;

          if (office?.plan === CargoMartPlansType.PRO) {
            if (isRateManagementActivated) {
              // Ensure submenu is active and points to the right page
              const submenuToReactivate = this.ratesSubMenus.find(
                (sb) => sb.title == submenu.title,
              );
              submenu.page = submenuToReactivate?.page;
              submenu.proTag = true;
              submenu.addonsTag = false;
            } else {
              submenu.page = null;
              submenu.proTag = false;
              submenu.addonsTag = true;
            }
          } else {
            submenu.page = null;
            submenu.proTag = true;
            submenu.addonsTag = false;
          }
        });

      // Update other submenus as per their specific features
      await this.updateSubMenu(
        this.menuHorService,
        UserFeaturesEnum.ANALYTICS_FORWARDER,
        $localize`:@@global.market-analysis:Market analysis`,
        $localize`:@@global.dashboards:Dashboards`,
      );

      await this.updateSubMenu(
        this.menuHorService,
        UserFeaturesEnum.ANALYTICS_AIRLINE_CONTACT,
        $localize`:@@global.airline-contact:Airline contacts`,
        $localize`:@@global.dashboards:Dashboards`,
      );

      await this.updateSubMenu(
        this.menuHorService,
        UserFeaturesEnum.QUOTATION,
        $localize`:@@menu-config.shipper:Shipper`,
        $localize`:@@global.quotes:Quotes`,
      );
    }
  }
  private isFeatureActivatedForSelectedOffice(
    feature: UserFeaturesEnum,
  ): boolean {
    const officeId = this.sessionService.getSelectedOfficeId(),
      office = this.sessionService
        .getCurrentUser()
        ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      feature,
    );
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter() {
    // check if the left aside menu is fixed
    if (!document.body.classList.contains('kt-menu__item--hover')) {
      this.render.addClass(document.body, 'kt-menu__item--hover');
    }
  }

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave(event: MouseEvent) {
    this.render.removeClass(event.target, 'kt-menu__item--hover');
  }

  /**
   * Return Css Class Name
   * @param item: any
   */
  getItemCssClasses(item) {
    let classes = 'kt-menu__item';

    if (objectPath.get(item, 'submenu')) {
      classes += ' kt-menu__item--submenu';
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' kt-menu__item--active kt-menu__item--here';
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' kt-menu__item--open kt-menu__item--here';
    }

    if (objectPath.get(item, 'resizer')) {
      classes += ' kt-menu__item--resize';
    }

    const menuType = objectPath.get(item, 'submenu.type') || 'classic';
    if (
      (objectPath.get(item, 'root') && menuType === 'classic') ||
      parseInt(objectPath.get(item, 'submenu.width'), 10) > 0
    ) {
      classes += ' kt-menu__item--rel';
    }

    const customClass = objectPath.get(item, 'custom-class');
    if (customClass) {
      classes += ' ' + customClass;
    }

    if (objectPath.get(item, 'icon-only')) {
      classes += ' kt-menu__item--icon-only';
    }

    return classes;
  }

  /**
   * Returns Attribute SubMenu Toggle
   * @param item: any
   */
  getItemAttrSubmenuToggle(item) {
    let toggle = 'hover';
    if (objectPath.get(item, 'toggle') === 'click') {
      toggle = 'click';
    } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
      toggle = 'tabs';
    } else {
      // submenu toggle default to 'hover'
    }

    return toggle;
  }

  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  getItemMenuSubmenuClass(item) {
    let classes = '';

    const alignment = objectPath.get(item, 'alignment') || 'right';

    if (alignment) {
      classes += ' kt-menu__submenu--' + alignment;
    }

    const type = objectPath.get(item, 'type') || 'classic';
    if (type === 'classic') {
      classes += ' kt-menu__submenu--classic';
    }
    if (type === 'tabs') {
      classes += ' kt-menu__submenu--tabs';
    }
    if (type === 'mega') {
      if (objectPath.get(item, 'width')) {
        classes += ' kt-menu__submenu--fixed';
      }
    }

    if (objectPath.get(item, 'pull')) {
      classes += ' kt-menu__submenu--pull';
    }

    return classes;
  }

  /**
   * Check Menu is active
   * @param item: any
   */
  isMenuItemIsActive(item): boolean {
    if (this.isSubPageActive(item)) {
      return true;
    }

    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1;
  }

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  isMenuRootItemIsActive(item): boolean {
    if (item.submenu.items) {
      for (const subItem of item.submenu.items) {
        if (this.isMenuItemIsActive(subItem)) {
          return true;
        }
      }
    }

    if (item.submenu.columns) {
      for (const subItem of item.submenu.columns) {
        if (this.isMenuItemIsActive(subItem)) {
          return true;
        }
      }
    }

    if (typeof item.submenu[Symbol.iterator] === 'function') {
      for (const subItem of item.submenu) {
        const active = this.isMenuItemIsActive(subItem);
        if (active) {
          return true;
        }
      }
    }

    return false;
  }

  isSubPageActive(item): boolean {
    if (!item.subPages) {
      return false;
    }

    return item.subPages.some(
      (subPage) => this.currentRouteUrl.indexOf(subPage) !== -1,
    );
  }

  get isCargoMartProEnabled(): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }
  get isProEnabled(): boolean {
    const selectedOffice = this.sessionService.getSelectedOffice();

    // Check if the selected office is on the Pro plan
    return selectedOffice?.plan === CargoMartPlansType.PRO;
  }

  showUpgradeMessage(parentItem, item) {
    if (!parentItem || !item || !item.proTag) {
      return;
    }
    if (
      this.sessionService.getSelectedOffice()?.plan ===
        CargoMartPlansType.PRO ||
      this.sessionService.getCurrentUser()?.type === UserTypeEnum.AIRLINE
    ) {
      return;
    }

    if (!this.upgradePopUpClosed) {
      return;
    }
    this.upgradePopUpClosed = false;
    const branchesSelectorModal = this.modalService.open(ProGenericModal, {
      centered: true,
    });
    branchesSelectorModal.componentInstance.type = 'SUGGEST-UPGRADE';

    branchesSelectorModal.result.then(
      () => {
        this.upgradePopUpClosed = true;
      },
      () => {
        this.upgradePopUpClosed = true;
      },
    );
  }
  shouldShowLightFont(item: any): boolean {
    if (!this.isProEnabled && item.proTag === true) {
      return true;
    }

    if (this.isProEnabled && item.addonsTag === true) {
      return true;
    }

    return false;
  }

  get isAnalyticalDashboardActivatedAirline(): boolean {
    return HomepageCommonUtil.isFeatureEnabled(
      this.sessionService.getCurrentUser()?.activatedFeatures,
      UserFeaturesEnum.ANALYTICS_AIRLINE,
    );
  }
}
